import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { redirect } from "react-router-dom";

import { Button, Loader, Text } from "monday-ui-react-core";
import { SET_USER_ACCOUNT } from "../../states/slices/userSlice";
import { LinkConnect } from "../../resources/icons";
import MondayService from "../../services/MondayService";
import { MONDAY_APP_VERSION, MONDAY_CLIENT_ID } from "../../configs";
import useToast from "../../hooks/components/toastHook";
import Logger from "../../utilities/Logger";
import ViewOnly from "./ViewOnly";
import { verifyUser } from "../../requests/authRequests";
import * as paths from "../../routes/paths";
import useUser from "../../hooks/states/userHook";

type IContextData = {
	userId?: string;
	accountId?: string;
};
const mondayService = new MondayService();

export default function LoginPage() {
	const { userId, accountId } = useUser();
	const dispatch = useDispatch();
	const { ToastComponent, showToast } = useToast();
	const [isLoading, setLoading] = useState(true);
	// const [pingUserAuthentication, setPingUserAuthentication] = useState(false);
	const [isViewOnly, setViewOnly] = useState(false);
	const [contextData, setContextData] = useState<IContextData>({
		accountId,
		userId,
	});

	// Sent for user insights on monday
	mondayService.sendValueCreatedForUser();

	//check if user is verified from our end
	const checkUserVerification = async () => {
		try {
		// 	if (contextData?.userId && contextData?.accountId) {
		// 		const verify = await verifyUser(contextData.userId, contextData?.accountId);
		// 		if (verify.status === 200) {
		// 			dispatch(
		// 				SET_USER_ACCOUNT({
		// 					userId: contextData.userId,
		// 					accountId: contextData.accountId,
		// 				})
		// 			);
		// 			setPingUserAuthentication(false);
		// 			redirect(paths.upload);
		// 		}
		// 	}
		} catch (error) {
			Logger.error(error);
			throw error;
		}
	};

	const getUserContext = async () => {
		try {
			setLoading(true);
			// if (userId && accountId) {
			// 	// Checks for user existence
			// 	try {
			// 		await checkUserVerification();
			// 		return;
			// 	} catch (error) {
			// 		Logger.error(error);
			// 	}
			// }

			const context = await mondayService.getContext();

			if(context && context.user) {
				// Checks and set guest view
				setViewOnly(context.user.isGuest || context.user.isViewOnly);

				dispatch(
					SET_USER_ACCOUNT({
						userId: context.user.id,
						accountId: context.account.id,
					})
				);
				
				redirect(paths.upload);
			}
		} catch (error) {
			Logger.error(error);
			showToast({
				content: "Verification failed",
				type: "error",
				showIcon: true,
				closeable: true,
				position: {
					horizontal: "center",
					vertical: "top",
				},
			});
		} finally {
			setLoading(false);
		}
	};

	const synchUser = async () => {
		try {
			if (contextData && contextData.userId && contextData.accountId) {
				await checkUserVerification();
				return;
			}

			getUserContext();
			throw new Error("System error rectified");
		} catch (error) {
			Logger.error(error);
			await checkUserVerification();
		}
	};

	const handleAuthorization = async () => {
		const params = new URLSearchParams();
		params.set("client_id", MONDAY_CLIENT_ID);
		//This is used if you want to work with a particular version.
		//If it's not set it automatically picks this live version. It is not compulsory for production
		if (MONDAY_APP_VERSION)
			// Include app version
			params.set("app_version_id", MONDAY_APP_VERSION);

		const url = "https://auth.monday.com/oauth2/authorize?" + params.toString();
		window.open(url, "_blank");
		// setPingUserAuthentication(true);
	};

	//Sending a ping every second to see if the user validated the app
	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		if (pingUserAuthentication) {
	// 			synchUser();
	// 		} else {
	// 			clearInterval(intervalId);
	// 		}
	// 	}, 3 * 1000);
	// 	return () => clearInterval(intervalId);
	// }, [pingUserAuthentication]);

	useEffect(() => {
		getUserContext();
	}, []);

	return (
		<>
			{isLoading ? (
				<div className="w-dvw h-dvh gap-4 flex flex-col justify-center items-center">
					<Loader color={Loader.colors.PRIMARY} size={Loader.sizes.LARGE} />
				</div>
			) : (
				<>
					{isViewOnly ? (
						<ViewOnly />
					) : (
						<div className="flex items-center justify-center flex-col max-w-60 space-y-10">
							<div className="flex items-center justify-center flex-col space-y-2">
								<Text className="text-4xl">Connect your monday.com!</Text>
								<p className="text-center text-grayed">
									To sync boards with google sheets, please connect your monday.com account
								</p>
							</div>

							<Button
								size="medium"
								leftIcon={LinkConnect}
								onClick={handleAuthorization}
								className="text-white"
							>
								Authorize with monday.com
							</Button>
						</div>
					)}
				</>
			)}

			<ToastComponent />
		</>
	);
}
