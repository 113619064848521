import React, { useEffect, useState } from "react";
import { AppUserSettings } from "../../interfaces/monday.type";
import MondayService from "../../services/MondayService";
import { validateGoogleSheetUrl } from "../../utilities/sheetUtils";
import useToast from "../../hooks/components/toastHook";
import SettingIcon, { StopIcon } from "../../resources/icons/SettingIcon";
import Button from "../../components/Button";
import OnboardingThree from "../onboardingThree";
import OnboardingTwo from "../onboardingTwo";


export default function GoogleIframe() {
	const { ToastComponent, showToast } = useToast();
	const [settings, setSettings] = useState<AppUserSettings["data"]>(null); // State to store settings
	const [listenerUnsubscribe, setListenerUnsubscribe] = useState<Function | null>(null);
	const [isValid, setIsValid] = useState(true);
	const [btnState, setBtnState] = useState(false); // false is closed, true is opened

	const mondayService = new MondayService();

	useEffect(() => {
		// Listen for settings updates
		mondayService.closeSettings();
		const unsubscribe = mondayService.listen("settings", (response: any) => {
			// if sheet exists
			if (response.data.sheet) {
				// validate sheet url found
				const isValid = validateGoogleSheetUrl(response.data.sheet);
				if (!isValid) {
					setIsValid(false);
					// showToast({
					// 	closeable: true,
					// 	type: "error",
					// 	position: {
					// 		horizontal: "center",
					// 		vertical: "top",
					// 	},
					// 	showIcon: true,
					// 	duration: 50000,
					// 	content:
					// 		"That link is not embeddable! Open the app settings ⚙️ and enter an embeddable URL link",
					// });
					setSettings(null);
					return;
				}
				setSettings(response.data); // Update the state with new settings
				return;
			}
			setIsValid(true);
			setSettings({ ...response.data, sheet: "" });
			// showToast({
			// 	closeable: true,
			// 	type: "info",
			// 	position: {
			// 		horizontal: "center",
			// 		vertical: "top",
			// 	},
			// 	showIcon: true,
			// 	duration: 90000,
			// 	content: "Open the app settings ⚙️ and enter an embeddable URL link.",
			// });
		});
		setListenerUnsubscribe(() => unsubscribe);

		// Cleanup listener on unmount
		return () => {
			if (listenerUnsubscribe) {
				// console.log("Unsubscribing from context listener");
				listenerUnsubscribe();
			}
		};
	}, []);

	return settings && settings.sheet ? (
		<div>
			<iframe
				sandbox="allow-downloads allow-forms allow-modals allow-popups allow-scripts allow-same-origin allow-top-navigation-by-user-activation"
				allow="fullscreen;"
				title="Lupapps Embedded App"
				src={settings?.sheet || ""}
				style={{ border: "0px", width: "100%", height: "100vh" }}
			></iframe>
			<ToastComponent />
		</div>
	) : (
		<div
			className="w-screen"
			style={{
				minWidth: "100vw",
				minHeight: "100vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "hidden",
				backgroundColor: "#191b34",
			}}
		>
			<div style={{ alignItems: "center", margin: "15px 0px", color: "black" }}>
				<div
					className="text-center text-[14px]"
					style={{
						backgroundColor: isValid ? "#D4EBF8" : "#f4c3cb",
						padding: "12px 16px",
						width: "600px",
						maxWidth: "620.508px",
						height: "fit-content",
						borderRadius: "8px",
					}}
				>
					{isValid ? (
						<></>
						// <p className="flex flex-row align-center justify-center gap-1 font-medium">
						// 	Ready to embed?
						// </p>
					) : (
						<p className="flex flex-row align-center gap-1 font-medium">
							<StopIcon /> That link is not embeddable!
						</p>
					)}
					{isValid ? (
						<div>
							<p className="text-[10px] text-sm font-light">
								{/* Open view settings and paste your Google Sheet URL.{" "} */}
								To embed google sheet open the view setting {" "}
							</p>
							<Button
								size="xs"
								onClick={() => {
									if (btnState) {
										mondayService.closeSettings();
									} else {
										mondayService.openSettings();
									}
									setBtnState(!btnState);
								}}
							>
								{!btnState ? "open settings" : "close settings"}
								<SettingIcon />
							</Button>
						</div>
					) : (
						<p className="text-[10px] text-sm font-light">
							Open the app settings ⚙️ and enter a valid sharable URL link
						</p>
					)}
					{/* <p className="text-[10px] text-sm font-light">
						<span className="!font-medium">Need help?</span> Check the steps below or chat with us!
						⤵️
					</p> */}
				</div>
			</div>

			<div
				className="pb-8 w-full"
				style={{
					// display: "flex",
					// flexDirection: "column",
					// alignItems: " center",
					overflowY: "auto",
					height: "calc(100vh - 7px)",
				}}
			>
				<OnboardingThree />
			</div>
		</div>
	);
}
