import React from "react";
import Button from "../components/Button";
import useCrisp from "../hooks/components/crispHook";
import { CheckCircle2, ArrowRight, BookOpen, MessageCircle, PlayCircle } from 'lucide-react';

interface VideoCardProps {
  videoUrl: string;
}

const VideoCard: React.FC<VideoCardProps> = ({ videoUrl }) => {
  return (
    <div className="w-full max-w-2xl mx-auto rounded-xl overflow-hidden shadow-lg my-4 bg-white">
      <div className="relative w-full aspect-video">
        <iframe
          className="w-full h-full"
          src={videoUrl}
          title="Integration Guide"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

function OnboardingThree() {
  useCrisp();

  const bookCall = () => {
		window.open("https://tidycal.com/lupapps", "_blank");
	};

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#cbeec5] to-white !pb-10">
      <div className="container max-w-[1000px] mx-auto px-4 py-8">
        {/* Success Banner */}
        <div className="text-center mb-12">
          {/* <div className="inline-flex items-center justify-center px-4 py-1 mb-4 bg-green-100 rounded-full">
            <CheckCircle2 className="w-4 h-4 text-green-600 mr-2" />
            <span className="text-green-600 font-medium">Integration Connected Successfully</span>
          </div> */}
          <h1 className="text-4xl font-bold mb-4">
            Welcome to Google Sheets Integration
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Let&apos;s get you set up with everything you need to supercharge your workflow with Google Sheets and monday.com
          </p>
        </div>

        {/* Main Content Card */}
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
          {/* Video Section */}
          <div className="mb-8">
            <div className="flex items-center mb-4">
              <PlayCircle className="w-6 h-6 text-blue-600 mr-2" />
              <h2 className="text-2xl font-bold">Quick Start Guide</h2>
            </div>
            <VideoCard videoUrl="https://www.youtube.com/embed/UHm55CVkdOM?si=QvAqyqNFYONeZga7" />
            <p className="text-center text-sm text-gray-600 mt-2">
              Watch this 2-minute video to learn the basics
            </p>
          </div>

          {/* Key Features */}
          <div className="grid md:grid-cols-2 gap-6 mb-8">
            <div className="bg-blue-50 p-6 rounded-xl">
              <h3 className="font-bold text-xl mb-4">Embedding Sheets</h3>
              <ol className="space-y-3">
                <li className="flex items-start">
                  <span className="flex-shrink-0 w-6 h-6 text-blue-600 rounded-full mr-3 bg-blue-100 flex items-center justify-center">
                    1
                  </span>
                  <div>
                    <a
                      href="https://gsi.crisp.help/en/article/embed-google-sheets-into-mondaycom-1ssvary"
                      className="hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Copy Sheet URL</p>
                      <p className="text-sm text-gray-600">Open your Google Sheet and copy its URL</p>
                    </a>
                  </div>
                </li>

                <li className="flex items-start">
                  <span className="flex-shrink-0 w-6 h-6 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mr-3">
                    2
                  </span>
                  <div>
                    <a
                      href="https://gsi.crisp.help/en/article/embed-google-sheets-into-mondaycom-1ssvary"
                      className="hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Open Settings</p>
                      <p className="text-sm text-gray-600">Click the ⚙️ icon in your monday.com board</p>
                    </a>
                  </div>
                </li>

                <li className="flex items-start">
                  <span className="flex-shrink-0 w-6 h-6 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center mr-3">
                    3
                  </span>
                  <div>
                    <a
                      href="https://gsi.crisp.help/en/article/embed-google-sheets-into-mondaycom-1ssvary"
                      className="hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Paste & Connect</p>
                      <p className="text-sm text-gray-600">Paste the shared URL to embed your sheet</p>
                    </a>
                  </div>
                </li>
              </ol>
            </div>

            <div className="bg-purple-50 p-6 rounded-xl">
              <h3 className="font-bold text-xl mb-4">Automation Types</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <div className="flex-shrink-0 w-2 h-2 bg-purple-400 rounded-full mt-2 mr-3"></div>
                  <div>
                    <a 
                      href="https://gsi.crisp.help/en/article/selective-data-exports-to-google-sheets-1h3jqs3/"
                      className="block hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Selective Exports [EX-SE]</p>
                      <p className="text-sm text-gray-600">Export specific data to sheets</p>
                    </a>
                  </div>
                </li>

                <li className="flex items-start">
                  <div className="flex-shrink-0 w-2 h-2 bg-purple-400 rounded-full mt-2 mr-3"></div>
                  <div>
                    <a 
                      href="https://gsi.crisp.help/en/article/full-data-exports-to-google-sheets-11bxsoq/"
                      className="block hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Full Exports [EX-FE]</p>
                      <p className="text-sm text-gray-600">Export entire boards or groups</p>
                    </a>
                  </div>
                </li>

                <li className="flex items-start">
                  <div className="flex-shrink-0 w-2 h-2 bg-purple-400 rounded-full mt-2 mr-3"></div>
                  <div>
                    <a 
                      href="https://gsi.crisp.help/en/article/data-imports-from-google-sheets-1hsowzu/"
                      className="block hover:text-blue-600 transition-colors"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="font-medium">Data Imports [IM]</p>
                      <p className="text-sm text-gray-600">Import sheets into monday.com</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Quick Links */}
          <div className="grid md:grid-cols-2 gap-4">
            <a 
              href="https://gsi.crisp.help"
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center">
                <BookOpen className="w-5 h-5 text-gray-600 mr-2" />
                <span className="font-medium">Documentation</span>
              </div>
              <ArrowRight className="w-4 h-4" />
            </a>

            <button 
              onClick={bookCall}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
            >
              <div className="flex items-center">
                <MessageCircle className="w-5 h-5 text-gray-600 mr-2" />
                <span className="font-medium">Schedule a Demo</span>
              </div>
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Help Section */}
        <div className="bg-white rounded-xl p-6 text-center shadow-lg">
          <div className="flex items-center justify-center mb-4">
            <MessageCircle className="w-6 h-6 text-blue-600 mr-2" />
            <h3 className="text-xl font-bold">Need Help?</h3>
          </div>
          <p className="text-gray-600 mb-4">
            Our team is ready to help you set up custom solutions tailored to your workflow
          </p>
          <Button onClick={bookCall} className="bg-blue-600 hover:bg-blue-700">
            Book a Call
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OnboardingThree;
