import React from "react";
import OnboardingThree from "./onboardingThree";

export default function OnboardingPage() {
	return (
		<div style={{ overflowY: "auto", height: "100vh" }}>
			<OnboardingThree />
		</div>
	);
}
